import React, { Component } from "react";
import { Link } from "gatsby";
import iconMessage from "../../../images/iconMessage.svg";

import "./footer.scss";

class FooterSection extends Component {
    render() {
        return (
            <section className="section footer-section">
                <div className="container">
                    <div className="footer-content mb-5 justify-content-between d-flex flex-column flex-md-row">
                        <div className="footer-text">
                            Если вы хотите пообщаться с нами и узнать больше
                            подробностей об HRFile, чтобы принять максимально
                            взвешенное решение, свяжитесь с нами по почте:
                        </div>
                        <div className="footer-links d-flex justify-content-center justify-content-md-start">
                            <div className="footer-link-item">
                                <div className="footer-link-icon">
                                    <img src={iconMessage} alt="Иконка почты" />
                                </div>
                                <a
                                    href="mailto: info@hrfile.ru"
                                    className="footer-link-text"
                                >
                                    info@HRFile.ru
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright-row d-flex flex-column flex-md-row justify-content-between">
                        <div className="footer-copyright flex-grow-1 order-3 order-md-1 text-center text-md-start">
                            ©HRFile, 2025
                        </div>
                        <Link
                            className="footer-link-button order-2 order-md-2 text-center text-md-start"
                            to="/software-information"
                        >
                            О компании
                        </Link>
                        <Link
                            className="footer-link-button order-1 order-md-3 text-center text-md-start"
                            to="/personal-data-policy"
                        >
                            Политика в отношении обработки персональных данных
                        </Link>
                    </div>
                </div>
            </section>
        );
    }
}

export default FooterSection;
